import React from 'react';
import { hydrate, render } from "react-dom";
import './assets/styles/reset.css'
import './assets/styles/fontface.css'
import './assets/styles/app.css'
import App from './components/App/App';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}