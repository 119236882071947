import "./HomeContacts.css";

export default function HomeContacts() {
  return (
    <div className="HomeContacts">
      <div className="HomeContacts__content">
        <div className="HomeContacts__title">Контакты</div>

        <div className="HomeContacts__rows">
          <div className="HomeContacts__row">
            <div className="HomeContacts__label">Звонки</div>
            <a
              className="HomeContacts__phone"
              href="tel:89871917673"
              title="Позвонить">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.4914 0.672938L10.6477 0.0167016C10.3387 -0.0543907 10.0216 0.106934 9.89578 0.396772L8.58331 3.45921C8.46846 3.72717 8.54502 4.04162 8.77197 4.22482L10.429 5.58104C9.44462 7.67826 7.72473 9.42275 5.58376 10.4262L4.22754 8.76925C4.04161 8.5423 3.72989 8.46574 3.46193 8.58058L0.399495 9.89305C0.106923 10.0216 -0.0544015 10.3387 0.0166907 10.6477L0.672927 13.4914C0.741285 13.7867 1.00378 14 1.31276 14C8.31534 14 14 8.32629 14 1.31277C14 1.00652 13.7894 0.741296 13.4914 0.672938Z"
                  fill="currentColor"
                />
              </svg>
              8-987-191-76-73
            </a>
          </div>

          <div className="HomeContacts__row">
            <div className="HomeContacts__label">
              Звонки,{" "}
              <a
                className="HomeContacts__blue"
                href="https://t.me/Mebelnaymasterskay75"
                rel="noreferrer"
                target="_blank">
                Telegram
              </a>
              ,{" "}
              <a
                className="HomeContacts__green"
                href="https://wa.me/79228296175"
                target="_blank"
                rel="noreferrer">
                Whats App
              </a>
              , <span className="HomeContacts__purple">Viber</span>
            </div>
            <a
              className="HomeContacts__phone"
              href="tel:89228296175"
              title="Позвонить">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.4914 0.672938L10.6477 0.0167016C10.3387 -0.0543907 10.0216 0.106934 9.89578 0.396772L8.58331 3.45921C8.46846 3.72717 8.54502 4.04162 8.77197 4.22482L10.429 5.58104C9.44462 7.67826 7.72473 9.42275 5.58376 10.4262L4.22754 8.76925C4.04161 8.5423 3.72989 8.46574 3.46193 8.58058L0.399495 9.89305C0.106923 10.0216 -0.0544015 10.3387 0.0166907 10.6477L0.672927 13.4914C0.741285 13.7867 1.00378 14 1.31276 14C8.31534 14 14 8.32629 14 1.31277C14 1.00652 13.7894 0.741296 13.4914 0.672938Z"
                  fill="currentColor"
                />
              </svg>
              8-922-829-61-75
            </a>
          </div>
        </div>

        <div className="HomeContacts__footer">
          <div className="HomeContacts__footerPrimary">
            <span>Виталий</span>
            <span>09:00 - 23:00</span>
          </div>
          <div className="HomeContacts__footerSecondary">
            без праздников и выходных
          </div>
        </div>
      </div>
    </div>
  );
}
