import "./HomeMain.css";
import { Link } from "react-router-dom";

export default function HomeMain() {
  return (
    <div className="HomeMain">
      <div className="HomeMain__plates HomeMainPlates container">
        <div className="HomeMain__plate HomeMainPlate HomeMainPlate--custom">
          <h1 className="HomeMainPlate__title">Мебель на заказ</h1>
          <p className="HomeMainPlate__links">
            <Link to="/gallery/wardrobes" className="HomeMainPlate__link">
              шкафы
            </Link>
            <Link to="/gallery/wardrobes" className="HomeMainPlate__link">
              шкафы-купе
            </Link>

            <Link to="/gallery/wardrobes" className="HomeMainPlate__link">
              прихожие
            </Link>
            <Link to="/gallery/kitchens" className="HomeMainPlate__link">
              кухни
            </Link>
            <Link to="/gallery/tables" className="HomeMainPlate__link">
              столы
            </Link>

            <Link to="/gallery/racks" className="HomeMainPlate__link">
              стеллажи
            </Link>

            <Link to="/gallery/beds" className="HomeMainPlate__link">
              кровати
            </Link>

            <Link to="/gallery/decor" className="HomeMainPlate__link">
              декор
            </Link>
          </p>

          <div className="HomeMainPlate__advantage HomeMainPlate__freeVisit">
            Выезд мастера бесплатно
          </div>
          <div className="HomeMainPlate__advantage HomeMainPlate__sales">
            Пенсионерам скидки
          </div>
        </div>

        <div className="HomeMainPlates__group">
          <div className="HomeMain__plate HomeMainPlate HomeMainPlate--assembly">
            <h2 className="HomeMainPlate__title">Сборка</h2>
          </div>

          <div className="HomeMain__plate HomeMainPlate HomeMainPlate--repair">
            <h1 className="HomeMainPlate__title">Ремонт мебели</h1>
            <p className="HomeMainPlate__links">
              <Link to="/contacts" className="HomeMainPlate__link">
                корпусная мебель
              </Link>
              <Link to="/contacts" className="HomeMainPlate__link">
                мягкая мебель
              </Link>
            </p>
          </div>

          <div className="HomeMain__plate HomeMainPlate HomeMainPlate--trimming">
            <h2 className="HomeMainPlate__title">Урезка</h2>
          </div>
        </div>

        <div className="HomeMainPlates__notice">Без праздников и выходных</div>

        <a
          className="HomeMainPlates__phone"
          href="tel:89228296175"
          title="Позвонить">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.4914 0.672938L10.6477 0.0167016C10.3387 -0.0543907 10.0216 0.106934 9.89578 0.396772L8.58331 3.45921C8.46846 3.72717 8.54502 4.04162 8.77197 4.22482L10.429 5.58104C9.44462 7.67826 7.72473 9.42275 5.58376 10.4262L4.22754 8.76925C4.04161 8.5423 3.72989 8.46574 3.46193 8.58058L0.399495 9.89305C0.106923 10.0216 -0.0544015 10.3387 0.0166907 10.6477L0.672927 13.4914C0.741285 13.7867 1.00378 14 1.31276 14C8.31534 14 14 8.32629 14 1.31277C14 1.00652 13.7894 0.741296 13.4914 0.672938Z"
              fill="currentColor"
            />
          </svg>
          <span>8-922-829-61-75</span>
        </a>
      </div>
    </div>
  );
}
