import './App.css';
import {lazy, Suspense, useEffect, useState} from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from '../../pages/Home/Home'
import AppToolbar from '../AppToolbar/AppToolbar';
import { Preloader } from '../Preloader/Preloader';

const Gallery = lazy(() => import('../../pages/Gallery/Gallery'));
const Contacts = lazy(() => import('../../pages/Contacts/Contacts'));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppToolbar />

        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/gallery/kitchens" element={<Suspense fallback={<div />}><Gallery title="Кухни" /></Suspense>} />
          <Route path="/gallery/wardrobes" element={<Suspense fallback={<div />}><Gallery title="Шкафы" /></Suspense>} />
          <Route path="/gallery/decor" element={<Suspense fallback={<div />}><Gallery title="Декор" /></Suspense>} />
          <Route path="/gallery/tables" element={<Suspense fallback={<div />}><Gallery title="Столы" /></Suspense>} />
          <Route path="/gallery/racks" element={<Suspense fallback={<div />}><Gallery title="Стеллажи" /></Suspense>} />
          <Route path="/gallery/beds" element={<Suspense fallback={<div />}><Gallery title="Кровати" /></Suspense>} />

          <Route path="/contacts" element={<Suspense fallback={<div />}><Contacts /></Suspense>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
