import './HomeServicesList.css'
import customImage1 from './images-custom/1.jpg'
import customImage2 from './images-custom/2.jpg'
import customImage3 from './images-custom/3.jpg'
import customImage4 from './images-custom/4.jpg'

import repairImage1 from './images-repair/1.jpg'
import repairImage2 from './images-repair/2.jpg'
import repairImage3 from './images-repair/3.jpg'
import repairImage4 from './images-repair/4.jpg'

import assemblyImage1 from './images-assembly/1.jpg'
import assemblyImage2 from './images-assembly/2.jpg'
import assemblyImage3 from './images-assembly/3.jpg'
import assemblyImage4 from './images-assembly/4.jpg'

import trimmingImage1 from './images-trimming/1.jpg'
import trimmingImage2 from './images-trimming/2.jpg'
import trimmingImage3 from './images-trimming/3.jpg'
import trimmingImage4 from './images-trimming/4.jpg'

export default function HomeServicesList() {
  return (
    <div className="HomeServicesList container">
      <div className="HomeServicesList__item HomeServicesListItem">
        <div className="HomeServicesListItem__left">
          <h3 className="HomeServicesListItem__title">Изготовление мебели</h3>
          <div className="HomeServicesListItem__divider"></div>
          <p className="HomeServicesListItem__desc">
            Выбирая мебель в магазине, всегда приходится идти на компромисс.
            У&nbsp;нас вы можете заказать такую мебель, которая лучше всего подходит именно Вам.
          </p>
          <div className="HomeServicesListItem__images">
            <img className="HomeServicesListItem__image" src={customImage1} alt="Шкаф" />
            <img className="HomeServicesListItem__image" src={customImage2} alt="Кровать" />
            <img className="HomeServicesListItem__image" src={customImage3} alt="Шкаф" />
          </div>
        </div>

        <div className="HomeServicesListItem__right">
          <img src={customImage4} alt="Стеллаж магазинный" />
        </div>
      </div>

      <div className="HomeServicesList__item HomeServicesListItem">
        <div className="HomeServicesListItem__right">
          <h3 className="HomeServicesListItem__title">Ремонт мебели</h3>
          <div className="HomeServicesListItem__divider"></div>
          <p className="HomeServicesListItem__desc">
            Может возникнуть ситуация, когда проще купить, чем отремонтировать.
            Но, если в вашем случае всё наоборот, свяжитесь с мастером и
            условьтесь о встрече в удобное для Вас время.
          </p>
          <div className="HomeServicesListItem__images">
            <img className="HomeServicesListItem__image" src={repairImage2} alt="Полка для цветов" />
            <img className="HomeServicesListItem__image" src={repairImage3} alt="Шкаф для вещей" />
            <img className="HomeServicesListItem__image" src={repairImage4} alt="Кухня бюджетная" />
          </div>
        </div>

        <div className="HomeServicesListItem__left">
          <img src={repairImage1} alt="Ремонт мягкой мебели" />
        </div>
      </div>

      <div className="HomeServicesList__item HomeServicesListItem">
        <div className="HomeServicesListItem__left">
          <h3 className="HomeServicesListItem__title">Сборка мебели</h3>
          <div className="HomeServicesListItem__divider"></div>
          <p className="HomeServicesListItem__desc">
          Заказали мебель в интернете и не хотите браться за сборку, боясь сделать что-то не так? Что ж, это мудрое решение, ведь такую работу лучше доверить мастеру.
          </p>
          <div className="HomeServicesListItem__images">
            <img className="HomeServicesListItem__image" src={assemblyImage2} alt="Стеллаж магазинный" />
            <img className="HomeServicesListItem__image" src={assemblyImage3} alt="Угловой шкаф" />
            <img className="HomeServicesListItem__image" src={assemblyImage4} alt="Туалетный столик" />
          </div>
        </div>

        <div className="HomeServicesListItem__right">
          <img src={assemblyImage1} alt="Шкафчик на балкон" />
        </div>
      </div>


      <div className="HomeServicesList__item HomeServicesListItem">
        <div className="HomeServicesListItem__right">
          <h3 className="HomeServicesListItem__title">Урезка мебели</h3>
          <div className="HomeServicesListItem__divider"></div>
          <p className="HomeServicesListItem__desc">
          Необходимость в уменьшении занимаемого мебелью пространства - вовсе не безвыходная ситуация. Урезка мебели - еще одна услуга, которую мы оказываем нашим клиентам.
          </p>
          <div className="HomeServicesListItem__images">
            <img className="HomeServicesListItem__image" src={trimmingImage2} alt="Компьютерный стол" />
            <img className="HomeServicesListItem__image" src={trimmingImage3} alt="Вещевой шкаф" />
            <img className="HomeServicesListItem__image" src={trimmingImage4} alt="Полки деревянные" />
          </div>
        </div>

        <div className="HomeServicesListItem__left">
          <img src={trimmingImage1} alt="Гардеробный шкаф" />
        </div>
      </div>
    </div>
  );
}
