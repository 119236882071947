import { Link } from "react-router-dom";
import "./HomePreview.css";
import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image4 from "./images/4.jpg";
import image5 from "./images/5.jpg";
import image6 from "./images/6.jpg";

export default function HomePreview() {
  return (
    <div className="HomePreview">
      <div className="HomePreview__title">Галерея работ</div>

      <div className="HomePreview__items">
        <div className="HomePreview__itemsRow">
          <a
            className="HomePreview__item"
            href="/gallery/kitchens"
            title="Перейти в галерею работ">
            <img src={image2} alt="Кухни на заказ" />
            <span className="HomePreview__itemTitle">Кухни</span>
          </a>

          <a
            className="HomePreview__item"
            href="/gallery/wardrobes"
            title="Перейти в галерею работ">
            <img src={image1} alt="Ремонт и изготовление шкафов" />
            <span className="HomePreview__itemTitle">Шкафы</span>
          </a>

          <a
            className="HomePreview__item"
            href="/gallery/decor"
            title="Перейти в галерею работ">
            <img src={image3} alt="Ремонт и изготовление декор-мебели" />
            <span className="HomePreview__itemTitle">Декор</span>
          </a>
        </div>

        <div className="HomePreview__itemsRow">
          <a
            className="HomePreview__item"
            href="/gallery/beds"
            title="Перейти в галерею работ">
            <img src={image5} alt="Ремонт и изготовление кроватей" />
            <span className="HomePreview__itemTitle">Кровати</span>
          </a>

          <a
            className="HomePreview__item"
            href="/gallery/tables"
            title="Перейти в галерею работ">
            <img
              src={image4}
              alt="Ремонт и изготовление кухонных и компьютерных столов"
            />
            <span className="HomePreview__itemTitle">Столы</span>
          </a>

          <a
            className="HomePreview__item"
            href="/gallery/racks"
            title="Перейти в галерею работ">
            <img
              src={image6}
              alt="Ремонт и изготовление стеллажей для магазинов"
            />
            <span className="HomePreview__itemTitle">Стеллажи</span>
          </a>
        </div>
      </div>

      <div className="HomePreview__items--mobile">
        <div className="HomePreview__itemsRow">
          <a
            className="HomePreview__item"
            href="/gallery/kitchens"
            title="Перейти в галерею работ">
            <img src={image2} alt="Ремонт и изготовление кухонь" />
            <span className="HomePreview__itemTitle">Кухонные гарнитуры</span>
          </a>
        </div>

        <div className="HomePreview__itemsRow">
          <a
            className="HomePreview__item"
            href="/gallery/wardrobes"
            title="Перейти в галерею работ">
            <img src={image1} alt="Ремонт и изготовление шкафов" />
            <span className="HomePreview__itemTitle">Шкафы</span>
          </a>
          <a
            className="HomePreview__item"
            href="/gallery/decor"
            title="Перейти в галерею работ">
            <img src={image3} alt="Ремонт и изготовление декор-мебели" />
            <span className="HomePreview__itemTitle">Декор</span>
          </a>
        </div>

        <div className="HomePreview__itemsRow">
          <a
            className="HomePreview__item"
            href="/gallery/tables"
            title="Перейти в галерею работ">
            <img
              src={image4}
              alt="Ремонт и изготовление кухонных и компьютерных столов"
            />
            <span className="HomePreview__itemTitle">Столы</span>
          </a>

          <a
            className="HomePreview__item"
            href="/gallery/beds"
            title="Перейти в галерею работ">
            <img src={image5} alt="Ремонт и изготовление кроватей" />
            <span className="HomePreview__itemTitle">Кровати</span>
          </a>
        </div>

        <div className="HomePreview__itemsRow">
          <a
            className="HomePreview__item"
            href="/gallery/racks"
            title="Перейти в галерею работ">
            <img
              src={image6}
              alt="Ремонт и изготовление стеллажей для магазинов"
            />
            <span className="HomePreview__itemTitle">Стеллажи</span>
          </a>
        </div>
      </div>

      <Link to="/gallery/wardrobes" className="HomePreview__action">
        Перейти в галерею
      </Link>
    </div>
  );
}
