import HomeContacts from "../../components/HomeContacts/HomeContacts";
import HomeMain from "../../components/HomeMain/HomeMain";
import HomePreview from "../../components/HomePreview/HomePreview";
import HomeServicesList from "../../components/HomeServicesList/HomeServicesList";

export default function Home() {
  return (
    <div>
      <HomeMain />

      <HomeServicesList />

      <HomePreview />

      <HomeContacts />
    </div>
  );
}
